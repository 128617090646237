import { MRT_RowData, MRT_TableInstance } from "material-react-table";
import { z, ZodSchema } from "zod";
import {
  deserializeRemix,
  RemixSerializedType,
  UseDataFunctionReturn,
} from "remix-typedjson";
import { useMatches } from "@remix-run/react";
import { AppData } from "@remix-run/react/dist/data";
import { NavLinks } from "~/constants/navLinks";
import { isObject, omitBy } from "lodash";

export const convertToArray = (
  val: string,
  fallback: string[] = []
): string[] | undefined => {
  if (typeof val === "string" && val === "") {
    return undefined;
  }
  return typeof val === "string" ? val.split(",") : fallback;
};

export const getUpdatedOrder = <T extends MRT_RowData, S = T>(
  table: MRT_TableInstance<T>,
  data: S[]
): S[] => {
  const tmpData = [...data];
  const { draggingRow, hoveredRow } = table.getState();
  if (hoveredRow && draggingRow && hoveredRow.index !== undefined) {
    const movedItem = tmpData.splice(draggingRow.index, 1)[0];
    if (movedItem === undefined) {
      // Gracefully handle the case where the dragged item is undefined
      return data;
    }
    tmpData.splice(hoveredRow.index, 0, movedItem);
  }
  return tmpData;
};

export const download = async (res: Response, filename: string) => {
  try {
    const blob = await res.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();

    // Clean up by revoking the URL
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw new Error(`Error downloading ${filename}`);
  }
};

const getUTCDate = (localDate: Date) =>
  new Date(
    Date.UTC(
      localDate.getFullYear(),
      localDate.getMonth(),
      localDate.getDate(),
      localDate.getHours(),
      localDate.getMinutes(),
      localDate.getSeconds()
    )
  );

export const convertDateToUTCTimestamp = (localDateString: string) => {
  const localDate = new Date(localDateString);
  return getUTCDate(localDate).valueOf();
};

export function formatZodError<T>(error: z.ZodError<T>) {
  return error.issues
    .map(
      (e) =>
        `{ Path:[${e.path.map((path) => path.toString()).join(", ")}], Code: "${
          e.code
        }", message: "${e.message}" }`
    )
    .join(", ");
}

export const safeParse = <Output>(schema: ZodSchema<Output>, data: unknown) => {
  const result = schema.safeParse(data);
  return result.success
    ? { data: result.data, error: null }
    : { data: null, error: result.error };
};

// FIXME: When we upgrade to Typescript 5.5., we can drop the type predicate (x is object) and use safer "type predicate inference"
export function IsObject(x: unknown): x is object {
  return typeof x === "object" && x !== null;
}

// FIXME: When we upgrade to Typescript 5.5., we can drop the type predicate (x is object) and use safer "type predicate inference"
export function isNotNullish<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function getValueFromOptions<U>(
  options: readonly U[],
  value: unknown
): U | undefined {
  return options.find((code) => code === value);
}

export type Success<T> = { success: true; failure: false; result: T };
export const success = <T>(result: T): Success<T> => ({
  success: true,
  failure: false,
  result,
});
export type Failure<E> = { success: false; failure: true; error: E };
export const failure = <E>(error: E): Failure<E> => ({
  success: false,
  failure: true,
  error,
});
export type Result<T, E> = Success<T> | Failure<E>;

export type Primitive =
  | bigint
  | boolean
  | null
  | number
  | string
  | symbol
  | undefined;

export type PlainObject = Record<string, Primitive>;

export function getPaginatedSchema<Output>(schema: ZodSchema<Output>) {
  return z.object({
    results: schema,
    total: z.number(),
    limit: z.number(),
  });
}

export function getOptionalPaginatedSchema<Output>(schema: ZodSchema<Output>) {
  return z.object({
    results: schema,
    total: z.number().optional(),
    limit: z.number().optional(),
  });
}

// Need to wait for https://github.com/colinhacks/zod/issues/2966, until .unknown() becomes more useful, then we can drop .refine & .transform
export const unknownPaginatedSchema = z
  .object({
    results: z
      .unknown()
      .refine((x) => x !== undefined, "result field required"),
    total: z.number(),
    limit: z.number(),
  })
  .transform(({ results, total, limit }) => ({
    results: results as unknown,
    total,
    limit,
  }));

export type UnknownPaginatedData = z.infer<typeof unknownPaginatedSchema>;

// Need to wait for https://github.com/colinhacks/zod/issues/2966, until .unknown() becomes more useful, then we can drop .refine & .transform
export const unknownOptionalPaginatedSchema = z
  .object({
    results: z
      .unknown()
      .refine((x) => x !== undefined, "result field required"),
    total: z.number().optional(),
    limit: z.number().optional(),
  })
  .transform((parseResult) => ({
    results: parseResult.results as unknown,
    total: parseResult.total,
    limit: parseResult.limit,
  }));

export type UnknownOptionalPaginatedData = z.infer<
  typeof unknownOptionalPaginatedSchema
>;

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeFirstLetters = (str: string): string => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const truncateTitle = (title: string): string => {
  const maxLength = 50;
  if (title.length > maxLength) {
    return title.substring(0, maxLength) + "...";
  }
  return title;
};

export function useTypedRouteLoaderDataFromPath<T extends AppData>(
  pathname: string
): UseDataFunctionReturn<T> | undefined {
  const match = useMatches().find((match) => match.pathname === pathname);
  if (!match) return undefined;
  return deserializeRemix<T>(match.data as RemixSerializedType<T>) as
    | UseDataFunctionReturn<T>
    | undefined;
}

// From https://stackoverflow.com/questions/71352751/string-replace-when-creating-string-literal-union-type-in-typescript
export type Replace<
  T extends string,
  S extends string,
  D extends string,
  A extends string = "",
> = T extends `${infer L}${S}${infer R}`
  ? Replace<R, S, D, `${A}${L}${D}`>
  : `${A}${T}`;

export interface ColumnFilter {
  id: string;
  value: unknown;
}
export type FilterQueryArgs = Record<string, Primitive>;

export function parseFilterData(filterData: ColumnFilter[]): FilterQueryArgs {
  const filterQueryArgs: FilterQueryArgs = {};
  filterData.forEach((cur) => {
    const value = cur.value;
    if (!isNotNullish(value)) {
      // Skip value
      return;
    }
    if (typeof value === "string") {
      filterQueryArgs[cur.id] = value;
      return;
    }
    if (typeof value === "number") {
      filterQueryArgs[cur.id] = value;
      return;
    }
    if (Array.isArray(value)) {
      filterQueryArgs[cur.id] = value.join();
      return;
    }
  });
  return filterQueryArgs;
}

export const getBreadcrumbData = (
  pathname: string,
  navlinks: NavLinks,
  slugIsId: boolean
): { baseText: string; nestedText?: string } => {
  const segments = pathname.split("/").filter((str) => Boolean(str));
  const flatLinks = navlinks.flatMap((obj) => obj.links);

  const baseMatch = flatLinks.find(
    (link) => link.path.slice(1).toLowerCase() === segments[0].toLowerCase()
  );

  const isId = (segment: string) => /^[a-f0-9]{24}$/i.test(segment) || slugIsId;

  const nestedSegment = segments.find(
    (segment, index) => index > 0 && !isId(segment)
  );

  return {
    baseText: baseMatch ? baseMatch.text : "",
    nestedText: nestedSegment?.replace("-", " "),
  };
};

export const getResourcesMappedById = <T extends { id: string }>(
  resources: T[] = []
): Record<string, T> =>
  resources.reduce(
    (acc, item) => {
      acc[item?.id] = item;
      return acc;
    },
    {} as Record<string, T>
  );

export const buildRowInfos = <T>(
  updatedRows: Record<string, boolean>,
  selectedRowsInfo: Record<string, T | null>,
  resourceMapById: Record<string, T>
): Record<string, T | null> =>
  Object.entries(updatedRows).reduce(
    (acc: Record<string, T | T | null>, [key, value]) => {
      if (value) {
        acc[key] = selectedRowsInfo[key] ?? resourceMapById[key];
      } else {
        acc[key] = null;
      }

      return acc;
    },
    {}
  );

export const omitTypename = <T extends Record<string, unknown>>(obj: T) => {
  const { __typename, ...rest } = obj;
  return rest;
};

export const getSanitizedInput = <T extends object>(
  input: T,
  keysToCheck: string[]
): T => removeEmptyObjects<T>(input, keysToCheck);

const removeEmptyObjects = <T extends object>(
  input: T,
  keysToCheck: string[]
): T =>
  omitBy(input, (value, key) => {
    if (keysToCheck.includes(key) && isObject(value) && !Array.isArray(value)) {
      const sanitizedValue = getSanitizedInput(value, Object.keys(value));
      return Object.values(sanitizedValue).every(
        (v) => v === "" || !isNotNullish(v)
      );
    }
    return false;
  }) as T;
